import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './components/login';
import AttendanceMenu from './components/attendance';
import AttendanceLogsPage from './components/attendance_logs_page';
import ImportData from './components/import_data';
import AlertMessageManager from './components/alert_message_manager';
import PermissionPage from './components/permission_confirmation';
import { jwtDecode } from 'jwt-decode';
import { isTokenExpire } from './libs/is_token_expire';

const AppRoutes: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkHaveToken = () => {
      const token = window.localStorage.getItem('token');
      const cameraPermission = window.localStorage.getItem('cameraPermission') || false;
      const locationPermission = window.localStorage.getItem('locationPermission') || false;
      if (token && token !== 'undefined') {
        const tokenDecode: { exp: number } = jwtDecode(token);
        if (!isTokenExpire(tokenDecode, 6)) {
          if (location.pathname === '/attendance_logs') {
            navigate('/attendance_logs');
          } else if (location.pathname === '/attendance' && cameraPermission && locationPermission) {
            navigate('/attendance'); 
          }
          else if (location.pathname === '/attendance' && cameraPermission === false && locationPermission === false) {
            navigate('/permission');
          }
        } else {
          window.localStorage.removeItem('token');
        }
      }
    };

    checkHaveToken();
  }, [navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/permission" element={<PermissionPage />} />
      <Route path="/attendance" element={<AttendanceMenu />} />
      <Route path="/import_data" element={<ImportData />} />
      <Route path="/manage_alert" element={<AlertMessageManager />} />
      <Route path="/attendance_logs" element={<AttendanceLogsPage />} />
      {/* Default route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
